import { navigateTo } from '#app'
import { useAuthStore } from '~/store/auth'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { token } = useAuthStore()
  const localePath = useLocalePath()
  if (token) {
    return navigateTo(localePath({ name: 'index' }))
  }
})
